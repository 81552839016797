<template>
  <b-row>
    <b-col :class="is_detail_network ? 'col-12 col-xl-6' : 'col-sm-5'">
      <b-card no-body :class="`${is_detail_network ? 'border-box-analytics' : ''} text-center`">
        <b-card-header>
          <h4 class="mb-0">
            {{$t('instagram.audienceGenres')}}
          </h4>
        </b-card-header>
        <component
        :is="apex_charts"
        height="235"
        :options="genders"
        :series="gendersTotal"
        :key="change_language"
        >
        </component>
        <b-row class="text-center mx-0">
          <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
            <b-card-text class="text-muted mb-0">
              {{$t('instagram.female')}}

            </b-card-text>
            <h3 class="font-weight-bolder mb-50">
              {{ gendersTotal[1] }}%
            </h3>
          </b-col>
          <b-col cols="6" class="border-top d-flex align-items-between flex-column py-1">
            <b-card-text class="text-muted mb-0">
              {{$t('instagram.male')}}

            </b-card-text>
            <h3 class="font-weight-bolder mb-50">
              {{ gendersTotal[0] }}%
            </h3>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col :class="is_detail_network ? 'col-12 col-xl-6' : 'col-sm-7'">
      <b-card no-body :class="is_detail_network ? 'border-box-analytics' : ''">
        <b-card-header>
          <b-card-title>
              {{$t('instagram.ageRange')}}
          </b-card-title>
        </b-card-header>
        <b-card-body class="pb-0">
          <div  class="d-flex justify-content-start mb-3">
            <div class="mr-2">
              <b-card-text class="mb-50">
                {{$t('instagram.female')}}
              </b-card-text>
              <h3 class="font-weight-bolder">
                <span style="color:#E75480">{{ gendersTotal[1] }}%</span>
              </h3>
            </div>
            <div class="mr-2">
              <b-card-text class="mb-50">
                {{$t('instagram.male')}}
              </b-card-text>
              <h3 class="font-weight-bolder">
                <span style="color:#5cb3ff">{{ gendersTotal[0] }}%</span>
              </h3>
            </div>
          </div>
          <component :is="apex_charts" type="line" height="200" :options="chartOptions" :series="series" :key="change_language"></component>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, BCardText  } from 'bootstrap-vue'
import { decimalFixed } from '@/libs/utils/formats'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
  },
  props: {
    graphData: {
      type: Object,
      required: true
    },
    is_detail_network: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      change_language: false,
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#5cb3ff', '#E75480'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#5cb3ff', '#E75480'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          title: {
            text:`${this.$t('instagram.ageRange')}`,
            offsetY: -10,
            style: {
              fontWeight: 400,
              fontSize: '1rem',
              color: '#b9b9c3'
            }
          },
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64'],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return `${val}%`
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
      genders: {
        labels: [`${this.$t('instagram.male')}`, `${this.$t('instagram.female')}`],
        yaxis: {
          labels: {
            formatter(val) {
              return `${decimalFixed(val, 2)}%`
            },
          },
        },
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: ['#5cb3ff', '#E75480'],
        plotOptions: {
          pie: {
            startAngle: 10,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseFloat(val)}%`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: `${this.$t('instagram.male')}`,
                  formatter: () => { return `${this.gendersTotal[0]}%` },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
      },
      apex_charts: null,
    }
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  computed: {
    data() {
      const obj = []
      const keys = Object.keys(this.graphData)
      for (let index = 0; index < keys.length; index++) {
        obj.push({male: this.graphData[keys[index]].male, female: this.graphData[keys[index]].female})
      }
      return obj
    },
    gendersTotal() {
      const reduceMale = (sum, item) => sum + item.male
      const reduceFemale = (sum, item) => sum + item.female
      const male = this.data.reduce(reduceMale, 0)
      const female = this.data.reduce(reduceFemale, 0)
      return [male, female]
    },
    male() {
      const male = []
      this.data.map(item => male.push(item.male))
      return male
    },
    female() {
      const female = []
      this.data.map(item => female.push(item.female))
      return female
    },
    series() {
      return [{name: `${this.$t('instagram.male')}`, data: this.male}, {name: `${this.$t('instagram.female')}`, data: this.female}]
    }
  },
  watch: {
    '$i18n.locale'() {
      this.change_language = !this.change_language;
      this.genders.labels =  [`${this.$t('instagram.male')}`,`${this.$t('instagram.female')}`];
      this.genders.plotOptions.pie.donut.labels.total.label = `${this.$t('instagram.male')}`;
      this.series[0].name =  `${this.$t('instagram.male')}`;
      this.series[1].name =  `${this.$t('instagram.female')}`;
      this.chartOptions.xaxis.title.text = `${this.$t('instagram.ageRange')}`;

    }
  }
}
</script>
